import React from "react"
import classNames from "classnames"

import { SECTION_NAMES } from "common/sectionNames"

import styles from "./ProgramSection.module.scss"
import commonStyles from "../../common/commonStyles.module.scss"

export default ({ onRef }) => (
  <div
    id={SECTION_NAMES.PROGRAM}
    ref={onRef}
    className={classNames(commonStyles.sectionWrapper, styles.sectionWrapper)}
  >
    <h2 className={classNames(commonStyles.title, styles.title)}>
      Jedes Programm eine eigene Welt
    </h2>
    <div className={styles.content}>
      <div className={styles.programs}>
        {/* Program 1 */}
        <p className={styles.date}>Samstag, 28. Juni 2025</p>
        <div>
          18 Uhr Bernardisaal, Stift Stams.
          <br />
          <br />
          (Ab 17:15 laden wir Sie auf einen Apero zur Einstimmung.)
        </div>
        <br />
        <div>
          <div>
            <span className={styles.composer}>Dieterich Buxtehude</span> &ndash;
            Trio in g-moll op.&thinsp;2, Nr.&thinsp;3
          </div>
          <div>
            <span className={styles.composer}>
              Francisco Guerau & Santiago de Murcia
            </span>{" "}
            &ndash; &bdquo;Marionas&ldquo; f&uuml;r Barockgitarre
          </div>
          <div>
            <span className={styles.composer}>Johann Michael Nicolai</span>{" "}
            &ndash; Sonata a tre in a-moll
          </div>
          <div>
            <span className={styles.composer}>Giuseppe Valentini</span> &ndash;
            Sonate in A-Dur &bdquo;La Montanari&rdquo;{" "}
            <nobr>f&uuml;r Violine und B.&thinsp;C.</nobr>
          </div>
          <div>
            <span className={styles.composer}>Johann Ernst Galliard</span>{" "}
            &ndash; Sonate Nr.&thinsp;5 in d-moll f&uuml;r Fagott und
            B.&thinsp;C
          </div>
          <div>
            <span className={styles.composer}>Antonio Vivaldi</span> &ndash;
            Triosonate in C-Dur, RV 801
          </div>
          <div>
            <span className={styles.composer}>Tarquinio Merula</span> &ndash;
            Ballo, Canzon und Ciaccona, op. 12
          </div>

          <div className={styles.programFooter}>
            Es spielen Mariya Nesterovska, Hubert Mittermayer Nesterovskiy,
            Fran&ccedil;ois Guerrier, Rafael Bonavita und{" "}
            <nobr>Oriol Aymat Fust&eacute;</nobr> auf historischen Instrumenten.
          </div>
          {/* Program 2 */}
          <p className={styles.date}>Sonntag, 6. Juli 2025</p>
          <div>
            18 Uhr Bernardisaal, Stift Stams.
            <br />
            <br />
            (Ab 17:15 laden wir Sie auf einen Apero zur Einstimmung.)
          </div>
          <br />
          <div>
            <span className={styles.composer}>Michail Glinka </span> &ndash;
            Trio path&eacute;tique f&uuml;r Klavier, Klarinette und Fagott
          </div>
          <div>
            <span className={styles.composer}>Arvo P&auml;rt</span> &ndash;
            Fratres f&uuml;r Violine und Klavier
          </div>
          <div>
            <span className={styles.composer}>Kyle Hovatter</span> &ndash;
            Diaphonic f&uuml;r Fagott und Elektronik (2014)
          </div>
          <div>
            <span className={styles.composer}>Wolfgang A. Mozart</span> &ndash;
            Sonate f&uuml;r Violine und Klavier <nobr>in G-Dur, KV 27</nobr>
          </div>
          <div>
            <span className={styles.composer}>Johannes Brahms</span> &ndash;
            Trio op.&thinsp;40 f&uuml;r Violine, Horn (Fagott){" "}
            <nobr>und Klavier</nobr>
          </div>
        </div>
        <div className={styles.programFooter}>
          Es spielen Mariya Nesterovska, Nenad Le&#269;i&#263;, Robert
          Oberaigner und Hubert Mittermayer Nesterovskiy.
        </div>
        {/* Datenschutz */}
        <p className={styles.dataprotectionText}>
          Hinweis: Bei unseren Veranstaltungen werden Foto-, Video- und
          Tonaufnahmen gemacht. Mit Buchung Ihres Tickets erkl&auml;ren Sie sich
          mit unseren{" "}
          <a href="https://www.obertone.at/datenschutz.pdf">
            Datenschutzhinweisen
          </a>{" "}
          einverstanden.
        </p>
      </div>
    </div>
  </div>
)
